// appointmentsSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance";
import { formatDate, formatDate3 } from "../../utils";
import { toast } from "react-toastify";

// Thunk to get appointment dates
export const getAppointmentDates = createAsyncThunk(
  "appointments/getAppointmentDates",
  async (practitionerId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/book-appoitment/get_appointment_dates/?id=${practitionerId}`
      );
      const data = response.data;
      if (data.status) {
        return data.appointment_dates;
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to get appointment schedules
export const getAppointmentSchedules = createAsyncThunk(
  "appointments/getAppointmentSchedules",
  async ({ practitionerId, selectedDate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/book-appoitment/get_appointment_timings/?doctor_id=${practitionerId}&date=${formatDate(selectedDate)}`
      );
      const data = response.data;
      if (data.status) {
        return {
          starttiming: data.starttiming,
          endtiming: data.endtiming,
        };
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const rescheduleAppointment = createAsyncThunk(
  'appointments/rescheduleAppointment',
  async ({ id, date, slot }, { rejectWithValue }) => {
    const Data = {
      appointmentid: id,
      date: formatDate3(date),
      start_time: slot.value.split("to")[0].trim(),
      end_time: slot.value.split("to")[1].trim(),
    };

    try {
      const response = await axiosInstance.post('/book-appoitment/reshedule_appointment/', Data);
      const data = response.data;
      if (data.status) {
        return data.message; // Success message
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Define slice
const appointmentsSlice = createSlice({
  name: "appointments",
  initialState: {
    availableDates: [],
    slotStartTiming: [],
    slotEndTiming: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetState: (state) => {
      state.availableDates = [];
      state.slotStartTiming = [];
      state.slotEndTiming = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Handle appointment dates fetching
    builder.addCase(getAppointmentDates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppointmentDates.fulfilled, (state, action) => {
      state.availableDates = action.payload;
      state.loading = false;
    });
    builder.addCase(getAppointmentDates.rejected, (state, action) => {
      state.loading = false;
      toast.warn(action.payload); // Show warning message
    });

    // Handle appointment schedules fetching
    builder.addCase(getAppointmentSchedules.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAppointmentSchedules.fulfilled, (state, action) => {
      state.slotStartTiming = action.payload.starttiming;
      state.slotEndTiming = action.payload.endtiming;
      state.loading = false;
    });
    builder.addCase(getAppointmentSchedules.rejected, (state, action) => {
      state.loading = false;
      toast.warn(action.payload); // Show warning message
    });

    // Handle reschedule appointment
    builder.addCase(rescheduleAppointment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(rescheduleAppointment.fulfilled, (state, action) => {
      toast.success(action.payload); // Show success message
      state.loading = false;
    });
    builder.addCase(rescheduleAppointment.rejected, (state, action) => {
      toast.warn(action.payload); // Show warning message
      state.loading = false;
    });
  },
});

export const { resetState } = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
