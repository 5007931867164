import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoader } from "../../context/loaderContext";
import axiosInstance from "../../api/axiosInstance";
import "./blacklist.css"; // Placeholder for CSS

// Sample data for blacklisted patients (this would typically come from an API)
const Blacklist = () => {
  const { showLoader, hideLoader } = useLoader();
  const [blacklistedPatients, setBlacklistedPatients] = useState([]);
  const [patientToRemove, setPatientToRemove] = useState(null);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  useEffect(() => {
    getBlacklisted();
  }, []);

  const getBlacklisted = () => {
    showLoader();
    axiosInstance
      .get("/blacklist/blacklist_patient/?status=True")
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setBlacklistedPatients(Data.data);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  // Handler to open the confirmation modal to remove a patient from the blacklist
  const handleRemoveConfirm = (patient) => {
    setPatientToRemove(patient);
    setShowRemoveConfirm(true);
  };

  // Handler for confirming the removal of a patient from the blacklist
  const confirmRemovePatient = () => {
    showLoader();
    const Data = {
      Patient_Account_Id: patientToRemove.Patient_Account_Id,
      reason: patientToRemove.black_list_reason,
      status: 'False',
    };

    axiosInstance
      .post(
        "/blacklist/blacklist_patient/",
        Data
      )
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          toast.success(Data.message);
          getBlacklisted();
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
        closeRemoveConfirm();
      });

    // setBlacklistedPatients(
    //   blacklistedPatients.filter(
    //     (p) => p.Patient_Account_Id !== patientToRemove.Patient_Account_Id
    //   )
    // );
  };

  // Handler to close the remove confirmation modal
  const closeRemoveConfirm = () => {
    setShowRemoveConfirm(false);
    setPatientToRemove(null); // Clear the selected patient
  };

  // Function to filter patients based on the search query
  const filteredPatients = blacklistedPatients.filter((patient) =>
    patient.Full_Name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="blacklist">
      <h2>Blacklisted Patients</h2>

      {/* Search Input */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by patient name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>

      {/* Blacklisted Patients List */}
      <div className="blacklist-list">
        <h3>Patients</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date of Birth</th>
              <th>Contact Info</th>
              <th>Address</th>
              <th>Blacklist Reason</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPatients.length > 0 ? (
              filteredPatients.map((patient) => (
                <tr key={patient.Patient_Account_Id}>
                  <td>{patient.Full_Name}</td>
                  <td>{patient.Date_of_Birth}</td>
                  <td>{patient.Email}</td>
                  <td>{patient.Street_Address}</td>
                  <td>{patient.black_list_reason}</td>
                  <td>
                    <button onClick={() => handleRemoveConfirm(patient)}>
                      Remove from Blacklist
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No patients found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Remove Confirmation Modal */}
      {showRemoveConfirm && (
        <div className="modal-overlay" onClick={closeRemoveConfirm}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Confirm Removal</h3>
            <p>
              Are you sure you want to remove {patientToRemove?.Full_Name} from
              the blacklist?
            </p>
            <button onClick={confirmRemovePatient}>Yes, Remove</button>
            <button className="close-button" onClick={closeRemoveConfirm}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Blacklist;
