import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { persistor, store } from "./Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const stripePromise = loadStripe(
  "pk_test_51PuvoI02E2bmaxle8xSVxAxTLz48Un2ouqze4Ugs1sLw4AnlQ3u4vPtsfFpSI7VszblboHB07HlW7gEQHOXckqML00wMLZ6TzE"
);

const root = createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </PersistGate>
  </Provider>
);
