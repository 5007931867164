import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import { toast } from "react-toastify";
import { useLoader } from "../../context/loaderContext";
import axiosInstance from "../../api/axiosInstance";
import "./login.css";

const Login = () => {
  const { showLoader, hideLoader } = useLoader();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    showLoader();
    const payload = {
      clinic_email: email,
      clinic_password: password,
    };
    axiosInstance
      .post(
        "/auth/clinic_login/",
        payload
      )
      .then((resp) => {
        // console.log(resp);
        const Data = resp.data;
        if (Data.status) {
          navigate("/verify-otp/", {
            state: { email: Data.clinic_email },
          });
        } else {
          // toast.warning(Data.message);
          setErrorMessage(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <div className="login-container">
      <img className="login-logo" src="logo.png" alt="Logo" />
      <div className="login-form">
        <img
          className="login-form-logo"
          src="Virtual Triage.png"
          alt="Virtual Triage Logo"
        />
        <h2 className="login-h2">Clinic Login</h2>
        {errorMessage && <p className="error">{errorMessage}</p>}
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
          <div className="sign-up-text">
            <p>
              Don't Have an Account?{" "}
              <Link to={"/signup"} className="signup-link">
                Sign up Here
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
