import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./otp-screen.css";

const OtpScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email"); // Extract the email from the URL

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    const otp = e.target.otp.value;

    // Send OTP and email to the OTP API
    fetch(
      "https://portal.virtualtriage.ca/api/v1/auth/clinic_signup_verification/",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, otp }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("OTP verified successfully!");
          navigate("/ClinicBranch");
        } else {
          alert(data.message || "OTP verification failed.");
        }
      })
      .catch((error) => {
        alert("An error occurred. Please try again later.");
      });
  };

  return (
    <div className="otp-screen">
      <div className="otp-box">
        <h2>Clinic Email Verification</h2>
        <p>
          We've sent a verification code to <strong>{email}</strong> Please
          enter that code here to proceed.
        </p>
        <form className="signup-otp-form" onSubmit={handleOtpSubmit}>
          <label>
            <input
              type="number"
              name="otp"
              placeholder="Enter OTP"
              required
              maxLength={6}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  e.target.value = value.slice(0, 6);
                }
              }}
            />
          </label>
          <br />
          <button className="submit-otp-btn" type="submit">
            Verify
          </button>
        </form>
      </div>
    </div>
  );
};

export default OtpScreen;
