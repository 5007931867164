// /src/api/axiosInstance.ts

import axios from 'axios';
import { getAccessToken, setAccessToken } from '../utils'; // Your custom token storage logic
// import { refreshToken } from './auth'; // API call to refresh token

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add request interceptor to attach token to every request
axiosInstance.interceptors.request.use((config) => {
  const token = getAccessToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

// Add response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If 401 Unauthorized and not retrying already
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // // Attempt to refresh token
        // const newAccessToken = await refreshToken();
        // setAccessToken(newAccessToken); // Update token in localStorage

        // // Retry original request with new token
        // axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        // originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

        // return axiosInstance(originalRequest); // Retry the request with the new token
      } catch (err) {
        // If token refresh fails, redirect to login or handle logout
        console.error('Token refresh failed:', err);
        // Optionally, you can redirect to login page here
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
