// Layout.js
import React from "react";
import { Outlet } from "react-router-dom"; // For rendering nested routes
import Sidebar from "./sidebar/sidebar"; // Import your sidebar component
import Topbar from "./topbar/topbar"; // Import your topbar component
import "./layout.css"; // Ensure you have CSS for layout

const Layout = () => {
  return (
    <>
      <div className="layout-container">
        <Sidebar />
        <div className="main-content">
          <Topbar />
          <div className="page-content">
            <Outlet /> {/* Render the child route content here */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
