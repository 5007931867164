/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useLoader } from "../../context/loaderContext";
import axiosInstance from "../../api/axiosInstance";
import "./billing.css";

// Load Stripe
const stripePromise = loadStripe(
  "pk_test_51PuvoI02E2bmaxle8xSVxAxTLz48Un2ouqze4Ugs1sLw4AnlQ3u4vPtsfFpSI7VszblboHB07HlW7gEQHOXckqML00wMLZ6TzE"
);

// Stripe Payment Form Component
const StripePaymentForm = ({
  clientSecret,
  setShowStripeModal,
  setPaymentMethods,
  paymentMethods,
  getPaymentMethods
}) => {
  const { showLoader, hideLoader } = useLoader();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    showLoader()
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    if (result.error) {
      toast.warn(result.error.message);
      console.log(result.error.message);
      hideLoader()
    } else {
      const data = { "customerid": result.paymentMethod.id }

      axiosInstance.post("/billing/add_billing/", data)
        .then((res) => {
          toast.success(res?.data?.message, { duration: 1000 });
          hideLoader();
          getPaymentMethods();
          setShowStripeModal(false);
        }).catch((err) => {
          toast.error(err.message);
          console.log(err.message);
          hideLoader();
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!stripe} className="billing-submit-btn">
        Add Payment Method
      </button>
      <button
        onClick={() => setShowStripeModal(false)}
        className="billing-close-btn"
      >
        Close
      </button>
    </form>
  );
};

// Export to CSV function
const exportToCSV = (transactions) => {
  const csvRows = [];
  const headers = [
    "Date",
    "Time",
    "Type",
    "Amount",
    "Provider",
    "Transaction ID",
  ];
  csvRows.push(headers.join(",")); // add header row

  // Add transaction rows
  transactions.forEach((txn) => {
    const values = [
      txn.date,
      txn.time,
      txn.purpose_type,
      txn.amount,
      txn.provider,
      txn.transaction_id,
    ];
    csvRows.push(values.join(","));
  });

  // Create a CSV Blob
  const blob = new Blob([csvRows.join("\n")], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);

  // Create an invisible download link and click it
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", "transactions_history.csv");
  a.click();
};

// Billing Page Component
const BillingPage = () => {
  const { showLoader, hideLoader } = useLoader();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(
    "sk_test_51PhrUYK9RQxOD12kJdIP9N7fIgP0em5rZjRLOqulwIvnOh1jVaHwo9uxXg4mcqdgY1Eqih2kSnzEEuy1rcHL4iiv00Y2IwE1vK"
  );

  useEffect(() => {
    getPaymentMethods();
    getPaymentHistory();
  }, []);

  const getPaymentMethods = () => {
    showLoader();
    axiosInstance
      .get("/billing/add_billing/")
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setPaymentMethods(Data.data);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getPaymentHistory = () => {
    showLoader();
    axiosInstance
      .get("/payment/get_payment_history/")
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setTransactions(Data.data);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  // Export handler for button click
  const handleExport = () => {
    exportToCSV(transactions);
  };

  // Add Payment Method: Request Setup Intent from Backend
  const handleAddPaymentMethod = () => {
    setShowStripeModal(true); // Open the modal after receiving the client secret
  };

  const handleRemovePaymentMethod = (id) => {
    showLoader();
    const data = {
      card_id: id,
    };

    axiosInstance
      .delete("/billing/add_billing/", { data })
      .then((resp) => {
        const Data = resp.data;
        // console.log(resp);
        if (Data.status) {
          setPaymentMethods(
            paymentMethods.filter((method) => method.id !== id)
          );
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <div className="billing-page">
      <h2>Clinic Billing and Payment Methods</h2>

      {/* Clinic Payment Methods */}
      <div className="payment-methods">
        <h3>Payment Methods</h3>
        <ul>
          {paymentMethods.map((method) => (
            <li key={method.id} className="payment-method-item">
              <span>
                {/* {method.method} ({method.provider}) */}
                {method.customerid + " - ( " + method.email + " )"}
              </span>
              <button
                className="remove-btn"
                onClick={() => handleRemovePaymentMethod(method.id)}
              >
                Remove
              </button>
            </li>
          ))}
        </ul>

        {/* Add Payment Method */}
        <div className="add-payment-method">
          <button className="add-btn" onClick={handleAddPaymentMethod}>
            Add Payment Method
          </button>
        </div>
      </div>

      {/* Transactions History */}
      <div className="transactions-history">
        <h3>Transactions History</h3>
        {/* Export Button */}
        <button onClick={handleExport} className="export-btn">
          Export Transactions
        </button>
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Provider</th>
              <th>Transaction ID</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((txn) => (
              <tr key={txn.id}>
                <td>{txn.date}</td>
                <td>{txn.time}</td>
                <td>{txn.purpose_type}</td>
                <td>{txn.amount}</td>
                <td>{txn.provider}</td>
                <td>{txn.transaction_id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Stripe Payment Method Modal */}
      {showStripeModal && (
        <div
          className="stripe-modal-overlay"
          onClick={() => setShowStripeModal(false)}
        >
          <div
            className="stripe-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <img src="./logo.png" alt="Stripe-form-logo" />
            <h3 style={{ color: "white" }}>Add Payment Method</h3>
            <Elements stripe={stripePromise}>
              <StripePaymentForm
                clientSecret={clientSecret}
                setShowStripeModal={setShowStripeModal}
                setPaymentMethods={setPaymentMethods}
                paymentMethods={paymentMethods}
                getPaymentMethods={getPaymentMethods}
              />
            </Elements>
          </div>
        </div>
      )}
    </div>
  );
};

export default BillingPage;
