import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AllPractitioners from "../pages/practitioners";
import Calendar from "../pages/calendar";
import Patients from "../pages/patients";
import Blacklist from "../pages/blacklist";
import Subscriptions from "../pages/subscription";
import Profile from "../pages/profile";
import Appointments from "../pages/appointments";
import Revenue from "../pages/revenue";
import Billing from "../pages/billing";
import Login from "../pages/login";
import OTPVerification from "../pages/otp-verification";
import Layout from "../layout";
import { useLoader } from "../context/loaderContext";
import Loader from "../components/loader";
import ClinicBranches from "../pages/clinic-branch/ClinicBranches";
import SignUp from "../pages/signup/signup";
import OTPScreen from "../pages/signup/otp-screen";

export default function AppRoutes() {
  const { isLoading } = useLoader();
  return (
    <>
      {isLoading && <Loader />}
      <Router>
        <Routes>
          {/* Login route, outside layout */}
          <Route path="/login" element={<Login />} />
          <Route path="/verify-otp" element={<OTPVerification />} />
          <Route path="/signup" element={<SignUp/>}/>
          <Route path="/otp" element={<OTPScreen />} />

          {/* Routes inside the layout */}
          <Route element={<Layout />}>
            <Route path="ClinicBranch" element={<ClinicBranches />} />
            <Route path="/Practitioners" element={<AllPractitioners />} />
            <Route path="/Appointments" element={<Appointments />} />
            <Route path="/Calendar" element={<Calendar />} />
            <Route path="/Patients" element={<Patients />} />
            <Route path="/Revenue" element={<Revenue />} />
            <Route path="/Blacklist" element={<Blacklist />} />
            <Route path="/Subscription" element={<Subscriptions />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/Billing" element={<Billing />} />
          </Route>

          {/* Redirect all unmatched routes to login */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </>
  );
}
