import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoader } from "../../context/loaderContext";
import "./manage-patients.css";
import axiosInstance from "../../api/axiosInstance";


const ManagePatients = () => {
  const { showLoader, hideLoader } = useLoader();
  const [patients, setPatients] = useState([]);
  const [newPatient, setNewPatient] = useState({
    Full_Name: "",
    Date_of_Birth: "",
    Email: "",
    Gender: "",
    Street_Address: "",
    medicalHistory: "",
    appointments: {
      booked: 0,
      attended: 0,
      paymentsMade: 0,
      outstandingBalance: 0,
      upcomingAppointments: 0,
    },
  });
  const [editingPatient, setEditingPatient] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null); // For profile view
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false); // For profile modal
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [blacklistedPatients, setBlacklistedPatients] = useState([]); // To handle blacklisted patients
  const [showBlacklistConfirmModal, setShowBlacklistConfirmModal] =
    useState(false); // Confirmation modal for blacklist
  const [blacklistReason, setBlacklistReason] = useState(""); // State to store the blacklist reason

  useEffect(() => {
    getPatients();
  }, []);

  const getPatients = () => {
    showLoader();
    axiosInstance
      .get("/patient/clinic_patient/")
      .then((resp) => {
        const Data = resp.data;
        // console.log(resp);
        if (Data.status) {
          setPatients(Data.data);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
        setShowModal(false);
      });
  };

  // Handler for adding a new patient
  const handleAddPatient = () => {
    showLoader();
    const formdata = new FormData();
    formdata.append("Full_Name", newPatient.Full_Name);
    formdata.append("Date_of_Birth", newPatient.Date_of_Birth);
    formdata.append("Mobile_Number", newPatient.Mobile_Number);
    formdata.append("Email", newPatient.Email);
    formdata.append("Street_Address", newPatient.Street_Address);
    formdata.append("Gender", newPatient.Gender);
    axiosInstance
      .post("/patient/clinic_patient/", formdata)
      .then((resp) => {
        const Data = resp.data;
        // console.log(resp);
        if (Data.status) {
          toast.success(Data.message);
          getPatients();
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp."Unable to Add Patient");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
        setShowModal(false);
      });
  };

  const handleBlacklist = () => {
    showLoader();
    const Data = {
      Patient_Account_Id: selectedPatient.Patient_Account_Id,
      reason: blacklistReason,
      status: "True",
    };

    axiosInstance
      .post("/blacklist/blacklist_patient/", Data)
      .then((resp) => {
        const Data = resp.data;
        // console.log(resp);
        if (Data.status) {
          toast.success(Data.message);
          setShowProfileModal(false); // Open the profile view modal
          setShowBlacklistConfirmModal(false); // Close the confirmation modal
          getPatients();
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
        setShowModal(false);
      });
  };

  // Handler for opening the modal to edit an existing patient
  const handleEditPatient = (patient, e) => {
    e.stopPropagation();
    setEditingPatient(patient);
    setSelectedPatient(null);
    setNewPatient(patient);
    setShowModal(true);
  };

  // Handler for saving the edited patient
  const handleSavePatient = () => {
    showLoader();
    // setPatients(
    //   patients.map((p) => (p.id === editingPatient.id ? newPatient : p))
    // );
    // setEditingPatient(null);
    const data = {
      Patient_Account_Id: newPatient.Patient_Account_Id,
      Full_Name: newPatient.Full_Name,
      Gender: newPatient.Gender,
      Date_of_Birth: newPatient.Date_of_Birth,
      Mobile_Number: newPatient.Mobile_Number,
      Street_Address: newPatient.Street_Address,
    };
    // const formdata = new FormData();
    // formdata.append("Full_Name", newPatient.Full_Name);
    // formdata.append("Date_of_Birth", newPatient.Date_of_Birth);
    // formdata.append("Mobile_Number", newPatient.Mobile_Number);
    // formdata.append("Email", newPatient.Email);
    // formdata.append("Street_Address", newPatient.Street_Address);
    // formdata.append("Patient_Account_Id", newPatient.Patient_Account_Id);

    axiosInstance
      .put("/patient/clinic_patient/", data)
      .then((resp) => {
        const Data = resp.data;
        // console.log(resp);
        if (Data.status) {
          getPatients();
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
        setShowModal(false);
      });
  };

  // Handler for opening the delete confirmation modal
  const handleDeleteConfirm = (patient, e) => {
    e.stopPropagation();
    setPatientToDelete(patient);
    setShowDeleteConfirm(true); // Open the delete confirmation modal
  };

  // Handler for confirming patient deletion
  const confirmDeletePatient = () => {
    showLoader();
    // setPatients(patients.filter((p) => p.id !== patientToDelete.id));
    // setShowDeleteConfirm(false);
    // setPatientToDelete(null);
    const data = {
      Patient_Account_Id: patientToDelete.Patient_Account_Id,
    };
    axiosInstance
      .delete("/patient/clinic_patient/", {
        data,
      })
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          getPatients();
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
        setShowModal(false);
        setShowDeleteConfirm(false);
        setPatientToDelete(null);
      });
  };

  // Function to open profile view modal
  const handleRowClick = (patient) => {
    setSelectedPatient(patient);
    setShowProfileModal(true); // Open the profile view modal
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingPatient(null);
    setSelectedPatient(null);
  };

  const closeProfileModal = () => {
    setShowProfileModal(false); // Close profile view modal
    setSelectedPatient(null);
  };

  const closeDeleteConfirm = () => {
    setShowDeleteConfirm(false);
    setPatientToDelete(null);
  };

  // Open blacklist confirmation modal
  const confirmBlacklistPatient = (patient) => {
    setSelectedPatient(patient);
    setBlacklistReason(""); // Reset the blacklist reason when opening the modal
    setShowBlacklistConfirmModal(true);
  };

  // Blacklist the patient after confirmation
  // const handleBlacklist = () => {
  //   if (!blacklistReason.trim()) {
  //     alert("Please provide a reason for blacklisting.");
  //     return;
  //   }

  //   setBlacklistedPatients([
  //     ...blacklistedPatients,
  //     { id: selectedPatient.id, reason: blacklistReason },
  //   ]);
  //   setShowBlacklistConfirmModal(false); // Close the confirmation modal
  //   alert(
  //     `${selectedPatient.Full_Name} has been blacklisted. Reason: ${blacklistReason}`
  //   );
  // };

  const handleRemoveFromBlacklist = (id) => {
    setBlacklistedPatients(
      blacklistedPatients.filter((patient) => patient.id !== id)
    );
    alert("Patient has been removed from blacklist.");
  };

  const addPatient = () => {
    setNewPatient({
      Full_Name: "",
      Date_of_Birth: "",
      Email: "",
      Gender: "",
      Street_Address: "",
      medicalHistory: "",
      appointments: {
        booked: 0,
        attended: 0,
        paymentsMade: 0,
        outstandingBalance: 0,
        upcomingAppointments: 0,
      },
    });
    setShowModal(true);
  };

  return (
    <div className="manage-patients">
      <h2>Manage Patients</h2>

      <button className="add-patient-button" onClick={addPatient}>
        Add Patient
      </button>

      {/* Patients List */}
      <div className="patients-list">
        <h3>Patients</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date of Birth</th>
              <th>Contact Info</th>
              <th>Gender</th>
              <th>Address</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {patients.length > 0 ? (
              patients.map((patient) => (
                <tr
                  key={patient.Patient_Account_Id}
                  onClick={() => handleRowClick(patient)}
                >
                  <td>{patient.Full_Name}</td>
                  <td>{patient.Date_of_Birth}</td>
                  <td>{patient.Email}</td>
                  <td>{patient.Gender}</td>
                  <td>{patient.Street_Address}</td>
                  <td>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditPatient(patient, e);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteConfirm(patient, e);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No patients found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Add/Edit Patient Modal */}
      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>
              {editingPatient
                ? "Edit Patient"
                : selectedPatient
                ? "Patient Details"
                : "Add Patient"}
            </h3>
            {selectedPatient ? (
              <>
                <p>Name: {selectedPatient.Full_Name}</p>
                <p>Date of Birth: {selectedPatient.Date_of_Birth}</p>
                <p>Contact Info: {selectedPatient.Email}</p>
                <p>Mobile_Number: {selectedPatient.Mobile_Number}</p>
                <p>Gender: {selectedPatient.Gender}</p>
                <p>Address: {selectedPatient.Street_Address}</p>
                <p>
                  <strong>Appointments Booked:</strong>{" "}
                  {selectedPatient?.appointments.booked}
                </p>
                <p>
                  <strong>Appointments Attended:</strong>{" "}
                  {selectedPatient?.appointments.attended}
                </p>
                <p>
                  <strong>Total Payments Made:</strong> $
                  {selectedPatient?.appointments.paymentsMade}
                </p>
                <p>
                  <strong>Outstanding Balance:</strong> $
                  {selectedPatient?.appointments.outstandingBalance}
                </p>
                <p>
                  <strong>Upcoming Appointments:</strong>{" "}
                  {selectedPatient?.appointments.upcomingAppointments}
                </p>

                {/* Blacklist Button */}
                {!blacklistedPatients.find(
                  (patient) => patient.id === selectedPatient.id
                ) ? (
                  <button
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      marginTop: "10px",
                    }}
                    onClick={() => confirmBlacklistPatient(selectedPatient)}
                  >
                    Blacklist
                  </button>
                ) : (
                  <button
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      marginTop: "10px",
                    }}
                    onClick={() =>
                      handleRemoveFromBlacklist(selectedPatient.id)
                    }
                  >
                    Remove from Blacklist
                  </button>
                )}

                <button onClick={closeModal} style={{ marginTop: "10px" }}>
                  Close
                </button>
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Name"
                  value={newPatient.Full_Name}
                  onChange={(e) =>
                    setNewPatient({ ...newPatient, Full_Name: e.target.value })
                  }
                />
                <input
                  type="date"
                  placeholder="Date of Birth"
                  value={newPatient.Date_of_Birth}
                  onChange={(e) =>
                    setNewPatient({
                      ...newPatient,
                      Date_of_Birth: e.target.value,
                    })
                  }
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  value={newPatient.Email}
                  onChange={(e) =>
                    setNewPatient({
                      ...newPatient,
                      Email: e.target.value,
                    })
                  }
                />
                <input
                  type="number"
                  placeholder="Mobile Number"
                  value={newPatient.Mobile_Number}
                  onChange={(e) =>
                    setNewPatient({
                      ...newPatient,
                      Mobile_Number: e.target.value,
                    })
                  }
                />
                <select
                  value={newPatient.Gender}
                  onChange={(e) =>
                    setNewPatient({ ...newPatient, Gender: e.target.value })
                  }
                  className="gender-select"
                >
                  <option value="" disabled>
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Unisexual">Unisexual</option>
                </select>
                <textarea
                  placeholder="Address"
                  value={newPatient.Street_Address}
                  onChange={(e) =>
                    setNewPatient({
                      ...newPatient,
                      Street_Address: e.target.value,
                    })
                  }
                />
                <button
                  onClick={
                    editingPatient ? handleSavePatient : handleAddPatient
                  }
                >
                  {editingPatient ? "Save Changes" : "Add Patient"}
                </button>
                <button className="close-button" onClick={closeModal}>
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {/* Profile View Modal */}
      {showProfileModal && selectedPatient && (
        <div className="modal-overlay" onClick={closeProfileModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>Patient Profile</h3>
            <p>
              <strong>Name:</strong> {selectedPatient.Full_Name}
            </p>
            <p>
              <strong>Date of Birth:</strong> {selectedPatient.Date_of_Birth}
            </p>
            <p>
              <strong>Email: </strong> {selectedPatient.Email}
            </p>
            <p>
              <strong>Mobile Number: </strong> {selectedPatient.Mobile_Number}
            </p>
            <p>
              <strong>Gender:</strong> {selectedPatient.Gender}
            </p>
            <p>
              <strong>Address:</strong> {selectedPatient.Street_Address}
            </p>
            <p>
              <strong>Appointments Booked:</strong>{" "}
              {selectedPatient?.appointments?.booked}
            </p>
            <p>
              <strong>Appointments Attended:</strong>{" "}
              {selectedPatient?.appointments?.attended}
            </p>
            <p>
              <strong>Total Payments Made:</strong> $
              {selectedPatient?.appointments?.paymentsMade}
            </p>
            <p>
              <strong>Outstanding Balance:</strong> $
              {selectedPatient?.appointments?.outstandingBalance}
            </p>
            <p>
              <strong>Upcoming Appointments:</strong>{" "}
              {selectedPatient?.appointments?.upcomingAppointments}
            </p>

            {/* Blacklist Button (Red Color) */}
            {!blacklistedPatients.find(
              (patient) => patient.id === selectedPatient.id
            ) ? (
              <button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  marginTop: "10px",
                }}
                onClick={() => confirmBlacklistPatient(selectedPatient)}
              >
                Blacklist
              </button>
            ) : (
              <button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  marginTop: "10px",
                }}
                onClick={() => handleRemoveFromBlacklist(selectedPatient.id)}
              >
                Remove from Blacklist
              </button>
            )}

            <button onClick={closeProfileModal} style={{ marginTop: "10px" }}>
              Close
            </button>
          </div>
        </div>
      )}

      {/* Blacklist Confirmation Modal */}
      {showBlacklistConfirmModal && (
        <div
          className="modal-overlay"
          onClick={() => setShowBlacklistConfirmModal(false)}
        >
          <div
            className="modal-content"
            onClick={
              (e) =>
                e.stopPropagation() /* Prevent modal from closing on inner clicks */
            }
          >
            <h3>
              Are you sure you want to blacklist {selectedPatient.Full_Name}?
            </h3>
            <textarea
              placeholder="Enter reason for blacklisting"
              value={blacklistReason}
              onChange={(e) => setBlacklistReason(e.target.value)}
              style={{ width: "100%", height: "100px", marginBottom: "10px" }}
            />
            <button onClick={handleBlacklist}>Yes, Blacklist</button>
            <button onClick={() => setShowBlacklistConfirmModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="modal-overlay" onClick={closeDeleteConfirm}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>
              Are you sure you want to delete {patientToDelete?.Full_Name}?
            </h3>
            <button onClick={confirmDeletePatient}>Yes, Delete</button>
            <button onClick={closeDeleteConfirm}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManagePatients;
