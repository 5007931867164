// redux/rootReducer.ts
import { combineReducers } from '@reduxjs/toolkit';
// Import your reducers here
import appointmentReducer from '../features/appointmentSlice';

const rootReducer = combineReducers({
  appointment: appointmentReducer,
  // Add other reducers here
});

export default rootReducer;