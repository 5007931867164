import { isSameDay, parse } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useNavigate } from "react-router-dom"; // For redirecting to the Manage Patients page
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import styled from "styled-components";
import axiosInstance from "../../api/axiosInstance";
import Modal from "../../components/modal/calendarModal";
import { useLoader } from "../../context/loaderContext";
import {
  customStyles,
  formatDate,
  formatDate2,
} from "../../utils";
import { rescheduleAppointment } from "../../Redux/features/appointmentSlice";

const localizer = momentLocalizer(moment);

// const appointments = [
//   {
//     id: 1,
//     title: "John Doe - Dr. Smith",
//     start: new Date("2024-10-2 10:15"),
//     end: new Date("2024-10-2 10:30"),
//     patientName: "John Doe",
//     practitionerName: "Dr. Smith",
//     status: "Pending",
//     owedAmount: "$200",
//   },
//   {
//     id: 2,
//     title: "Jane Doe - Dr. Johnson",
//     start: new Date("2024-10-2 12:00"),
//     end: new Date("2024-10-2 13:00"),
//     patientName: "Jane Doe",
//     practitionerName: "Dr. Johnson",
//     status: "Confirmed",
//     owedAmount: "$150",
//   },
// ];

const CalendarContainer = styled.div`
  padding: 20px;
  width: 97%;
`;

const Button = styled.button`
  background-color: white;
  color: #50cada;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: 0.5s;
  border: 1px solid white;
  margin-right: 10px;
  &:hover {
    background-color: #3c9aa7;
    color: white;
    border: 1px solid white;
  }
`;

const BookButton = styled.div`
  background-color: #50cada;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: 0.5s;
  margin-right: 10px;
  width: fit-content;
  &:hover {
  background-color: #3c9aa7;
  color: white;
  `;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: white;
`;

const ModalContent = styled.div`
  background: linear-gradient(45deg, #40a1ad, #57e1f3);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  width: 500px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }

  select,
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
`;

const DatePickerContainer = styled.div`
  margin-bottom: 20px;
  position: relative;
  z-index: 5; // Ensures DatePicker appears on top
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: white;
  color: #333;

  &:focus {
    border-color: #50cada;
    outline: none;
  }

  &::placeholder {
    color: #aaa;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__day--selected {
    background-color: #50cada;
    color: white;
  }
`;

const BookAppointmentModal = ({ onClose, getAppoinments }) => {
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  // const [availableSlot, setAvailableSlot] = useState("");
  const [charting, setCharting] = useState("");
  const [patients, setPatients] = useState([]);
  const [practitioners, setPractitioners] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [slotStartTiming, setSlotStartTiming] = useState([]);
  const [slotEndTiming, setSlotEndTiming] = useState([]);
  const [slot, setSlot] = useState([]);
  const [bookSlots, setBookSlots] = useState([]);
  
  const patientsOptions = patients.map((patient) => ({
    value: patient.Patient_Account_Id,
    label: patient.Full_Name,
  }));

  patientsOptions.push({
    value: "new",
    label: "Add New Patient",
  });

  const practitionersOptions = practitioners.map((practitioner) => ({
    value: practitioner.Health_Professional_Id,
    label: practitioner.Full_Name,
  }));

  const availableSlotsOptions = slotStartTiming.map((e, index) => ({
    label: e + " to " + slotEndTiming[index],
    value: e + " to " + slotEndTiming[index],
  })) .filter(slot => !bookSlots.includes(slot.label.split(" to ")[0]));

  const availableDatesFormat = availableDates.map((dateStr) =>
    parse(dateStr, "MMM dd yyyy", new Date())
  );

  const handlePractitionerChange = (e) => {
    const practitionerId = e.value;
    const practitioner = practitioners.find(
      (p) => p.Health_Professional_Id === parseInt(practitionerId)
    );
    setSelectedPractitioner(practitioner);
  };

  // const handleBookAppointment = () => {
  //   if (
  //     !selectedPatient ||
  //     !selectedPractitioner ||
  //     !appointmentDate ||
  //     !availableSlot
  //   ) {
  //     alert("Please fill out all fields.");
  //     return;
  //   }

  //   onBookAppointment({
  //     id: Date.now(),
  //     title: `${selectedPatient.name} - ${selectedPractitioner.name}`,
  //     start: appointmentDate,
  //     end: new Date(appointmentDate.getTime() + 30 * 60000), // Assume 30 min slot
  //     patientName: selectedPatient.name,
  //     practitionerName: selectedPractitioner.name,
  //     status: "Pending",
  //     owedAmount: selectedPatient.outstandingBalance,
  //   });

  //   onClose();
  // };

  useEffect(() => {
    if (patients.length > 0) return;
    getPatients();
    if (practitioners.length > 0) return;
    getPractitioners();
  }, []);

  const getPatients = () => {
    showLoader();
    axiosInstance
      .get(`/book-appoitment/get_patient/`)
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setPatients(Data.data);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getPractitioners = () => {
    showLoader();
    axiosInstance
      .get(`/book-appoitment/get_doctor/`)
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setPractitioners(Data.data);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getAppointmentDates = useCallback(() => {
    showLoader();
    axiosInstance
      .get(
        `/book-appoitment/get_appointment_dates/?id=${selectedPractitioner?.Health_Professional_Id}`
      )
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setAvailableDates(Data.appointment_dates);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  }, [selectedPractitioner?.Health_Professional_Id]);

  useEffect(() => {
    if (selectedPractitioner?.Health_Professional_Id) {
      getAppointmentDates();
    }
  }, [selectedPractitioner?.Health_Professional_Id, getAppointmentDates]);

  const getAppointmentSchedules = useCallback(() => {
    showLoader();
    axiosInstance
      .get(
        `/book-appoitment/get_appointment_timings/?doctor_id=${
          selectedPractitioner?.Health_Professional_Id
        }&date=${formatDate(appointmentDate)}`
      )
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          console.log(Data);
          setSlotStartTiming(Data.starttiming);
          setSlotEndTiming(Data.endtiming);
          setBookSlots(Data.bookslots);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  }, [appointmentDate]);

  useEffect(() => {
    if (appointmentDate) {
      getAppointmentSchedules();
    }
  }, [appointmentDate, getAppointmentSchedules]);

  const isDateAvailable = (date) => {
    return availableDatesFormat.some((availableDate) =>
      isSameDay(date, availableDate)
    );
  };

  const newAppointment = () => {
    showLoader();
    const data = {
      Doctor_id: selectedPractitioner?.Health_Professional_Id,
      Patient_id: selectedPatient?.Patient_Account_Id,
      Date: formatDate2(appointmentDate),
      Time: slot.value,
    };

    axiosInstance
      .post(`/book-appoitment/clinic_bookappointment/`, data)
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          toast.success(Data.message);
          onClose();
          getAppoinments();
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <h3 style={{ textAlign: "center" }}>Book New Appointment</h3>
        <FormGroup>
          <label>Patient</label>
          <Select
            styles={customStyles}
            value={
              selectedPatient
                ? {
                    value: selectedPatient.Patient_Account_Id,
                    label: selectedPatient.Full_Name,
                  }
                : null
            }
            onChange={(selectedOption) => {
              if (selectedOption.value === "new") {
                navigate("/Patients"); // Redirect when "Add New Patient" is selected
              } else {
                const patient = patients.find(
                  (p) => p.Patient_Account_Id === parseInt(selectedOption.value)
                );
                setSelectedPatient(patient);
              }
            }}
            options={patientsOptions}
            placeholder="Select a patient"
            // isClearable
          />
        </FormGroup>
        <FormGroup>
          <label>Practitioner</label>
          <Select
            styles={customStyles}
            value={
              selectedPractitioner
                ? {
                    value: selectedPractitioner.Health_Professional_Id,
                    label: selectedPractitioner.Full_Name,
                  }
                : null
            }
            onChange={(selectedOption) => {
              handlePractitionerChange(selectedOption); // Pass selected option to the change handler
            }}
            options={practitionersOptions}
            placeholder="Select a practitioner"
            // isClearable
          />
        </FormGroup>
        <FormGroup>
          <label>Date</label>
          {/* <DatePicker
            selected={appointmentDate}
            onChange={(date) => setAppointmentDate(date)}
            dateFormat="dd-MMM-yyyy"
          /> */}
          <DatePicker
            selected={appointmentDate}
            onChange={(date) => setAppointmentDate(date)}
            filterDate={isDateAvailable}
            placeholderText="Select a date"
            dateFormat="yyyy-MM-dd"
            disabled={!selectedPractitioner}
            style={{ width: "100%" }}
          />
        </FormGroup>
        <FormGroup>
          <label>Available Slot</label>
          {/* <input
            type="time"
            placeholder="Enter available slot"
            value={availableSlot}
            onChange={(e) => setAvailableSlot(e.target.value)}
          /> */}
          <Select
            styles={customStyles}
            onChange={(selectedOption) => {
              setSlot(selectedOption); // Pass selected option to the change handler
            }}
            value={slot}
            options={availableSlotsOptions}
            placeholder="Select available slot"
            isDisabled={!availableSlotsOptions.length}
            isClearable
          />
        </FormGroup>
        <FormGroup>
          <label>Charting</label>
          <textarea
            placeholder="Enter charting details"
            value={charting}
            onChange={(e) => setCharting(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <strong>Outstanding Balance:</strong>{" "}
          {selectedPatient?.outstandingBalance || "N/A"}
        </FormGroup>
        <Button onClick={newAppointment}>Book Now</Button>{" "}
        {/* handleBookAppointment */}
        <Button onClick={onClose}>Discard</Button>
      </ModalContent>
    </ModalOverlay>
  );
};

const AppointmentsCalendar = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.appointment);
  const { showLoader, hideLoader } = useLoader();
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const events = filteredAppointments.map((appointment) => ({
    id: appointment.Book_Appointment_id,
    title: `${appointment.Patient_id} - ${appointment.Health_Professional}`,
    start: new Date(
      `${appointment.Date.split("-").reverse().join("-")} ${
        appointment.Time.split(" to ")[0]
      }`
    ),
    end: new Date(
      `${appointment.Date.split("-").reverse().join("-")} ${
        appointment.Time.split(" to ")[1]
      }`
    ),
    patientName: appointment.Patient_id,
    practitionerId: appointment.Health_Professional_id,
    practitionerName: appointment.Health_Professional,
    status: appointment.Status,
    owedAmount: "$200",
    patientImage: appointment.PatientImage,
    practitionerImage: appointment.Health_Professional_image,
  }));

  useEffect(() => {
    if (loading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [loading]);

  const handleSlotClick = (appointment) => {
    setSelectedAppointment(appointment);
    setShowModal(true);
  };

  useEffect(() => {
    getAppoinments();
  }, []);

  const getAppoinments = () => {
    showLoader();
    axiosInstance
      .get(`/book-appoitment/get_clinic_appointment/`)
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          console.log(Data);
          setFilteredAppointments(Data.data);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleReschedule = (date, slot) => {
    dispatch(rescheduleAppointment({ id: selectedAppointment.id, date, slot }))
      .unwrap() // To handle promises if needed
      .then(() => {
        getAppoinments(); // After success, get appointments again
        setShowModal(false); // Close modal
      })
      .catch((error) => {
        toast.error(error); // Handle errors if needed
      });
  };

  // const handleBookAppointment = (newAppointment) => {
  //   setFilteredAppointments([...filteredAppointments, newAppointment]);
  // };

  return (
    <CalendarContainer>
      <h2>Clinic Calendar</h2>

      {/* Book New Appointment Button */}
      <BookButton onClick={() => setShowAppointmentForm(true)}>
        Book New Appointment
      </BookButton>

      <DatePickerContainer>
        <StyledDatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="dd-MMM-yyyy"
          placeholderText="Select a date"
        />
      </DatePickerContainer>

      {/* Calendar */}
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500, zIndex: 1 }}
        onSelectEvent={handleSlotClick}
        defaultView="day"
        views={["month", "week", "day"]}
        defaultDate={selectedDate} // Move calendar to selected date
        date={selectedDate} // Move calendar to selected date
        onNavigate={(newDate) => setSelectedDate(newDate)}
        eventPropGetter={(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: "#50cada",
            color: "white",
            fontWeight: "bold",
            borderRadius: "0px",
            border: "none",
          };

          if (event.isMine) {
            newStyle.backgroundColor = "lightgreen";
          }

          return {
            className: "",
            style: newStyle,
          };
        }}
      />

      {/* Modal for appointment details */}
      {showModal && selectedAppointment && (
        <Modal
          appointment={selectedAppointment}
          onClose={() => setShowModal(false)}
          onReschedule={handleReschedule}
        />
      )}

      {/* Book Appointment Modal */}
      {showAppointmentForm && (
        <BookAppointmentModal
          getAppoinments={getAppoinments}
          // onBookAppointment={handleBookAppointment}
          onClose={() => setShowAppointmentForm(false)}
        />
      )}
    </CalendarContainer>
  );
};

export default AppointmentsCalendar;
