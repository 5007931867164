import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import { useLoader } from "../../context/loaderContext";
import axiosInstance from "../../api/axiosInstance";
import { customStyles, validateEmail } from "../../utils";
import "./all-practitioners.css";

const ManagePractitioners = () => {
  const [practitioners, setPractitioners] = useState([]);
  const [specialities, setSpecialies] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [newPractitioner, setNewPractitioner] = useState({
    Full_Name: "",
    Degree: "",
    Doctor_Speciality: "",
    Doctor_Commission: "",
    Date_of_Birth: "",
    profilePicture: "",
    Email: "",
    Street_Address: "",
    Health_Professional_Id: "",
    Clinic_Branch: "",
  });
  const [editingPractitioner, setEditingPractitioner] = useState(null);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [practitionerToDelete, setPractitionerToDelete] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const { showLoader, hideLoader } = useLoader();

  const specialitiesOptions = specialities.map((spec) => {
    return {
      value: spec.Hospital_Speciality_id,
      label: spec.Hospital_Speciality_Name,
    };
  });

  const clinicsOptions = clinics.map((spec) => {
    return {
      value: spec.Hospital_id,
      label: spec.Hospital_Name,
    };
  });

  useEffect(() => {
    getPractitioner();
  }, []);

  const getPractitioner = () => {
    axiosInstance
      .get("/doctor/clinic_doctor/")
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setPractitioners(Data.data);
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getSpeciality = () => {
    if (specialities.length > 0) return;
    axiosInstance
      .get("/speciality/clinic_speciality/")
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          setSpecialies(Data.data);
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const getClinics = () => {
    // showLoader();
    axiosInstance
      .get("clinic/clinic_hospital/")
      .then((resp) => {
        const Data = resp.data;
        // console.log(resp);
        if (Data.status) {
          setClinics(Data.data);
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    // .finally(() => {
    //   hideLoader();
    // });
  };

  const handleAddPractitioner = () => {
    console.log("newPractitioner--------", newPractitioner);
    if (
      newPractitioner.Full_Name === "" ||
      newPractitioner.Email === "" ||
      !validateEmail(newPractitioner.Email) ||
      newPractitioner.Doctor_Speciality === "" ||
      profilePicture === null ||
      newPractitioner.Clinic_Branch === ""
    ) {
      toast.warn("Please fill in all required fields.");
      return;
    }

    const formdata = new FormData();
    formdata.append("Full_Name", newPractitioner.Full_Name);
    formdata.append("Date_of_Birth", newPractitioner.Date_of_Birth);
    formdata.append("Email", newPractitioner.Email);
    formdata.append("Degree", newPractitioner.Degree);
    formdata.append("DoctorSpecility", newPractitioner.Doctor_Speciality);
    formdata.append("Doctor_Commission", newPractitioner.Doctor_Commission);
    formdata.append("Street_Address", newPractitioner.Street_Address);
    formdata.append("Health_Professional_Image", profilePicture);
    formdata.append("Clinic_Branch", newPractitioner.Clinic_Branch);

    console.log("formdata--------",formdata);

    axiosInstance
      .post("/doctor/clinic_doctor/", formdata)
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          getPractitioner();
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  const handleEditPractitioner = async (practitioner) => {
    getSpeciality();
    getClinics();
    setEditingPractitioner(practitioner);
    setNewPractitioner(practitioner);
    setSelectedPractitioner(null);
    setShowModal(true);
  };

  const handleSavePractitioner = () => {
    console.log("newPractitioner--------", newPractitioner);
    if (
      newPractitioner.Full_Name === "" ||
      newPractitioner.Email === "" ||
      !validateEmail(newPractitioner.Email) ||
      newPractitioner.Doctor_Speciality === "" ||
      newPractitioner.Clinic_Branch === ""
    ) {
      toast.warn("Please fill in all required fields.");
      return;
    }

    const formdata = new FormData();
    formdata.append("Full_Name", newPractitioner.Full_Name);
    formdata.append("Date_of_Birth", newPractitioner.Date_of_Birth);
    formdata.append("Degree", newPractitioner.Degree);
    formdata.append("DoctorSpecility", newPractitioner.Doctor_Speciality);
    formdata.append("Doctor_Commission", newPractitioner.Doctor_Commission);
    formdata.append("Street_Address", newPractitioner.Street_Address);
    formdata.append("Health_Professional_Id", newPractitioner.Health_Professional_Id);
    formdata.append("Health_Professional_Image", profilePicture);
    formdata.append("Clinic_Branch", newPractitioner.Clinic_Branch);

    axiosInstance
      .put("/doctor/clinic_doctor/", formdata)
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          getPractitioner();
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setShowModal(false);
      });
  };

  const handleDeleteConfirm = (practitioner, e) => {
    e.stopPropagation();
    setPractitionerToDelete(practitioner);
    setShowDeleteConfirm(true);
    // confirmDeletePractitioner(practitioner)
  };

  const confirmDeletePractitioner = (practitioner) => {
    showLoader();
    const data = {
      Health_Professional_Id: practitioner.Health_Professional_Id,
    };
    axiosInstance
      .delete("/doctor/clinic_doctor/",{
        data,
      })
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          getPractitioner();
          closeDeleteConfirm();
        } else {
          toast.warn(Data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
        setShowModal(false);
      });
  };

  const openModalForAdd = async () => {
    await Promise.all([getSpeciality(), getClinics()]);
    setNewPractitioner({
      Full_Name: "",
      Degree: "",
      Doctor_Speciality: "",
      Doctor_Commission: "",
      Date_of_Birth: "",
      profilePicture: "",
      Email: "",
      Street_Address: "",
      Clinic_Branch: "",
    });
    setEditingPractitioner(null);
    setSelectedPractitioner(null);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingPractitioner(null);
    setSelectedPractitioner(null);
  };

  const closeDeleteConfirm = () => {
    setShowDeleteConfirm(false);
    setPractitionerToDelete(null);
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewPractitioner({
          ...newPractitioner,
          profilePicture: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const openDetailsModal = (practitioner) => {
    setSelectedPractitioner(practitioner);
    setEditingPractitioner(null);
    setShowModal(true);
  };

  return (
    <div className="manage-practitioners">
      <h2>Manage Practitioners</h2>
      <button className="add-practitioner-button" onClick={openModalForAdd}>
        Add Practitioner
      </button>
      <div className="practitioners-list">
        <h3>Practitioners</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Degree</th>
              <th>Specialty</th>
              <th>Commission</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {practitioners.map((practitioner) => (
              <tr
                key={practitioner.Health_Professional_Id}
                onClick={() => openDetailsModal(practitioner)}
              >
                <td>{practitioner.Full_Name}</td>
                <td>{practitioner.Degree}</td>
                <td>{practitioner.Doctor_Speciality}</td>
                <td>{practitioner.Doctor_Commission}</td>
                <td>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditPractitioner(practitioner);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteConfirm(practitioner, e);
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <div className="practitioner-modal-overlay" onClick={closeModal}>
          <div
            className="practitioner-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h3>
              {editingPractitioner
                ? "Edit Practitioner"
                : selectedPractitioner
                ? "Practitioner Details"
                : "Add Practitioner"}
            </h3>
            {selectedPractitioner ? (
              <>
                <img
                  src={
                    selectedPractitioner.Health_Professional_Image ||
                    "./profile.jfif"
                  }
                  alt="Profile"
                  className="profile-picture-large"
                />
                <div className="practitioner-details-container">
                  <label>Name:</label>
                  <p>{selectedPractitioner.Full_Name}</p>
                  <label>Email:</label>
                  <p>{selectedPractitioner.Email}</p>
                  <label>Degree:</label>
                  <p>{selectedPractitioner.Degree}</p>
                  <label>Specialty:</label>
                  <p>{selectedPractitioner.Doctor_Speciality}</p>
                  <label>Date of Birth:</label>
                  <p>{selectedPractitioner.Date_of_Birth}</p>
                  <label>Commission:</label>
                  <p>{selectedPractitioner.Doctor_Commission}</p>
                  <label>Street Address:</label>
                  <p>{selectedPractitioner.Street_Address}</p>
                  <label>Clinic Branch:</label>
                  <p>{selectedPractitioner.Clinic_Branch}</p>
                </div>
                <button onClick={closeModal}>Close</button>
              </>
            ) : (
              <form>
                <label>
                  Name:
                  <input
                    type="text"
                    value={newPractitioner.Full_Name}
                    onChange={(e) =>
                      setNewPractitioner({
                        ...newPractitioner,
                        Full_Name: e.target.value,
                      })
                    }
                    placeholder="Enter Full Name"
                    required
                  />
                </label>
                <label>
                  Email:
                  <input
                    type="email"
                    value={newPractitioner.Email}
                    onChange={(e) =>
                      setNewPractitioner({
                        ...newPractitioner,
                        Email: e.target.value,
                      })
                    }
                    placeholder="Enter Email"
                    required
                  />
                </label>
                <label>
                  Degree:
                  <input
                    type="text"
                    value={newPractitioner.Degree}
                    onChange={(e) =>
                      setNewPractitioner({
                        ...newPractitioner,
                        Degree: e.target.value,
                      })
                    }
                    placeholder="Enter Degree"
                  />
                </label>
                <label>Specialty:</label>
                <Select
                  styles={customStyles}
                  // onChange={(selectedOption) => {
                  //   setSelectedSlot(selectedOption); // Pass selected option to the change handler
                  // }}
                  // value={selectedSlot}
                  onChange={(selectedOption) =>
                    setNewPractitioner({
                      ...newPractitioner,
                      Doctor_Speciality: selectedOption.value,
                    })
                  }
                  options={specialitiesOptions}
                  placeholder="Select Specialty"
                  isDisabled={!specialitiesOptions?.length}
                  // isClearable
                />
                <label>Clinic Branch:</label>
                {/* <input
                    type="text"
                    value={newPractitioner.Clinic_Branch}
                    onChange={(e) => setNewPractitioner({ ...newPractitioner, Clinic_Branch: e.target.value })}
                    placeholder="Enter Clinic Branch"
                  /> */}
                <Select
                  styles={customStyles}
                  // onChange={(selectedOption) => {
                  //   setSelectedSlot(selectedOption); // Pass selected option to the change handler
                  // }}
                  // value={selectedSlot}
                  onChange={(selectedOption) =>
                    setNewPractitioner({
                      ...newPractitioner,
                      Clinic_Branch: selectedOption.value,
                    })
                  }
                  options={clinicsOptions}
                  placeholder="Select Clinic Branch"
                  isDisabled={!clinicsOptions?.length}
                  // isClearable
                />
                {/* <select
                    value={newPractitioner.Doctor_Speciality}
                    onChange={(e) => setNewPractitioner({ ...newPractitioner, Doctor_Speciality: e.target.value })}
                  >
                    <option value="">Select Specialty</option>
                    {specialities.map((spec) => (
                      <option key={spec.speciality_id} value={spec.speciality_name}>
                        {spec.speciality_name}
                      </option>
                    ))}
                  </select> */}
                <label>
                  Date of Birth:
                  <input
                    type="date"
                    value={newPractitioner.Date_of_Birth}
                    onChange={(e) =>
                      setNewPractitioner({
                        ...newPractitioner,
                        Date_of_Birth: e.target.value,
                      })
                    }
                  />
                </label>
                <label>
                  Commission:
                  <input
                    type="text"
                    value={newPractitioner.Doctor_Commission}
                    onChange={(e) =>
                      setNewPractitioner({
                        ...newPractitioner,
                        Doctor_Commission: e.target.value,
                      })
                    }
                    placeholder="Enter Commission"
                  />
                </label>
                <label>
                  Street Address:
                  <input
                    type="text"
                    value={newPractitioner.Street_Address}
                    onChange={(e) =>
                      setNewPractitioner({
                        ...newPractitioner,
                        Street_Address: e.target.value,
                      })
                    }
                    placeholder="Enter Street Address"
                  />
                </label>
                <label>
                  Profile Picture:
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleProfilePictureChange}
                  />
                </label>
                <div className="modal-actions">
                  {editingPractitioner ? (
                    <button type="button" onClick={handleSavePractitioner}>
                      Save
                    </button>
                  ) : (
                    <button type="button" onClick={handleAddPractitioner}>
                      Add
                    </button>
                  )}
                  <button type="button" onClick={closeModal}>
                    Cancel
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
      {showDeleteConfirm && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete this practitioner?</p>
            <div className="modal-actions">
              <button onClick={() => confirmDeletePractitioner(practitionerToDelete)}>Delete</button>
              <button onClick={closeDeleteConfirm}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default ManagePractitioners;