import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; 
import "./signup.css";

const SignUp = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    clinic_name: "",
    email: "",
    mobile_number: "",
    password: "",
  });


  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { clinic_name, email, mobile_number, password } = formData;
    if (!clinic_name || !email || !mobile_number || !password) {
      return "All fields are required.";
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return "Invalid email format.";
    }
    if (!/^\+\d{10,15}$/.test(mobile_number)) {
      return "Phone number must be 10-15 digits.";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      return;
    }

    setError("");

    try {
      const response = await fetch(
        "https://portal.virtualtriage.ca/api/v1/auth/clinic_signup/",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        alert("Signup successful!");
        navigate(`/otp?email=${encodeURIComponent(formData.email)}`);
        setFormData({
          clinic_name: "",
          email: "",
          mobile_number: "",
          password: "",
        });
      } else {
        const data = await response.json();
        setError(data.message || "Signup failed. Please try again.");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="signup-page">
      <div className="signup-body">
        <div className="signup-container">
          <img className="signup-logo" src="/Virtual Triage.png" alt="Logo" />
          <h2>Clinic Sign Up</h2>
          {error && <p className="error-message">{error}</p>}
          <form onSubmit={handleSubmit}>
            <label>
              Clinic Name:
              <input
                type="text"
                name="clinic_name"
                value={formData.clinic_name}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Phone Number:
              <input
                type="text"
                name="mobile_number"
                value={formData.mobile_number}
                onChange={handleInputChange}
                required
              />
            </label>
            <label>
              Password:
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </label>
            <button type="submit">Sign Up</button>
          </form>
          <div className="sign-up-text">
            <p>
              Already Have an Account?{" "}
              <Link to={"/login"} className="signup-link">
                Login Here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default SignUp;
