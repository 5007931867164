/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { toast } from "react-toastify";
import Select from "react-select";
import axiosInstance from "../../api/axiosInstance";
import { useLoader } from "../../context/loaderContext";
import "./AddClinicBranchModal.css";

const libraries = ['places'];

const AddClinicBranchModal = ({
  show,
  handleClose,
  selectedClinic,
  isEdit,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries, // Use the constant here
  });
  const { showLoader, hideLoader } = useLoader();
  const [specialities, setSpecialies] = useState([]);

  const [clinicData, setClinicData] = useState({
    clinicName: "",
    clinicImage: "",
    clinicStatus: "Active",
    clinicSpecialty: "",
    clinicBio: "",
    clinicAbout: "",
    clinicAddress: "",
    clinicCurrentLocation: "",
    clinicLocation: { lat: 43.65107, lng: -79.347015 }, // Default Toronto coordinates
  });
  const [hospitalData, setHospitalData] = useState([]);

  useEffect(() => {
    getSpeciality();
  }, []);

  useEffect(() => {
    getMarkers(clinicData.clinicLocation.lat, clinicData.clinicLocation.lng);
  }, [clinicData?.clinicLocation?.lat, clinicData?.clinicLocation?.lng]);

  useEffect(() => {
    if (selectedClinic) {
      console.log(selectedClinic, "selectedClinic");

      setClinicData({
        clinicName: isEdit ? selectedClinic.Hospital_Name : "",
        clinicImage: "",
        clinicStatus:
          selectedClinic?.status !== undefined
            ? selectedClinic.status
            : "Active",
        clinicSpecialty: isEdit ? selectedClinic.Hospital_Speciality : "",
        clinicBio: isEdit ? selectedClinic.Bio : "",
        clinicAbout: isEdit ? selectedClinic.About : "",
        clinicAddress: "",
        clinicLocation: {
          lat: 43.65107,
          lng: -79.347015,
        },
        clinicCurrentLocation: {
          lat: parseFloat(selectedClinic?.latitude),
          lng: parseFloat(selectedClinic?.longitude),
        },
      });
    } else {
      setClinicData({
        clinicName: "",
        clinicImage: "",
        clinicStatus: "Active",
        clinicSpecialty: "",
        clinicBio: "",
        clinicAbout: "",
        clinicAddress: "",
        clinicCurrentLocation: "",
        clinicLocation: { lat: 43.65107, lng: -79.347015 },
      });
    }
  }, [selectedClinic]);

  const getMarkers = (lat, lng) => {
    axiosInstance
      .get(`https://portal.virtualtriage.ca/HospitalFetch1/${lat}/${lng}/10000`)
      .then((response) => {
        setHospitalData(response.data[1]);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching hospital data: ", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClinicData({ ...clinicData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setClinicData({ ...clinicData, clinicImage: file }); // Store the file object
    }
  };

  const getSpeciality = () => {
    showLoader();
    axiosInstance
      .get("/speciality/clinic_speciality/")
      .then((resp) => {
        if (resp.data.status) {
          const temp = resp?.data?.data?.map((specialty) => ({
            value: specialty.Hospital_Speciality_Name,
            label: specialty.Hospital_Speciality_Name,
          }));
          setSpecialies(temp);
        } else {
          toast.warn(resp?.data.message);
          // setErrorMessage(resp.message);
        }
      })
      .catch((error) => {
        console.log(error, "specialities");
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  // const handleAddressSearch = async (e) => {
  //   console.log('places', e);
  //   // const address = e.target.value;
  //   // setClinicData({ ...clinicData, clinicAddress: address });

  //   // if (address.length > 2) {
  //   // try {
  //   //   const response = await axios.get(
  //   //     `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
  //   //       address
  //   //     )}&key=${process.env.REACT_APP_MAP_KEY}`
  //   //   );

  //   //   if (response.data.results.length > 0) {
  //   //     const { lat, lng } = response.data.results[0].geometry;
  //   //     setClinicData({
  //   //       ...clinicData,
  //   //       clinicLocation: { lat, lng },
  //   //     });
  //   //   } else {
  //   //     console.warn("No results found for this address.");
  //   //   }
  //   // } catch (error) {
  //   //   console.error("Error fetching address data: ", error);
  //   // }
  //   // }
  // };

  // const handleMarkerClick = (e) => {
  //   console.log(e, "clinic marker");

  //   const newLocation = {
  //     lat: e.latitude,
  //     lng: e.longitude,
  //   };
  //   setClinicData({
  //     ...clinicData,
  //     clinicLocation: newLocation,
  //     clinicAddress: "", // Clear address on map click
  //   });
  // };

  const handleSave = () => {
    if (clinicData?.clinicName === "") {
      toast.warn("Please enter Clinic Name");
      return;
    } else if (clinicData?.clinicStatus === "") {
      toast.warn("Please enter clinic Status");
      return;
    } else if (clinicData?.clinicSpecialty === "") {
      toast.warn("Please select Clinic Specialty");
      return;
    } else if (clinicData?.clinicBio === "") {
      toast.warn("Please enter clinic Bio");
      return;
    } else if (clinicData?.clinicAbout === "") {
      toast.warn("Please enter clinic About");
      return;
    } else if (
      clinicData?.clinicCurrentLocation.lat === "" &&
      clinicData?.clinicCurrentLocation.lng === ""
    ) {
      toast.warn("Please enter clinic Location");
      return;
    }

    showLoader();

    const isEditMode = isEdit;
    let payload;
    let config;

    if (isEditMode) {
      payload = new URLSearchParams({
        latitude: clinicData?.clinicCurrentLocation.lat,
        longitude: clinicData?.clinicCurrentLocation.lng,
        name: clinicData?.clinicName,
        status: clinicData?.clinicStatus,
        hospitalspecility: clinicData?.clinicSpecialty,
        about: clinicData?.clinicAbout,
        Bio: clinicData?.clinicBio,
        cliniclocation: "Pakistan",
        hospitalId: selectedClinic?.Hospital_id,
      });
      config = { method: "put", url: "clinic/clinic_hospital/", data: payload };
    } else {
      if (clinicData?.clinicImage === null) {
        toast.warn("Please upload Image");
        return;
      }
      payload = new FormData();
      payload.append("latitude", clinicData?.clinicCurrentLocation.lat);
      payload.append("longitude", clinicData?.clinicCurrentLocation.lng);
      payload.append("image", clinicData?.clinicImage);
      payload.append("name", clinicData?.clinicName);
      payload.append("status", clinicData?.clinicStatus);
      payload.append("hospitalspecility", clinicData?.clinicSpecialty);
      payload.append("about", clinicData?.clinicAbout);
      payload.append("cliniclocation", "Pakistan");
      payload.append("placeinfo", "placeinfo");
      payload.append("Bio", clinicData?.clinicBio);
      config = {
        method: "post",
        url: "clinic/clinic_hospital/",
        data: payload,
      };
    }

    axiosInstance(config)
      .then((resp) => {
        const Data = resp.data;
        if (Data.status) {
          ClearAllField();
          toast.success(Data.message);
          handleClose();
        } else {
          toast.warn(Data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const ClearAllField = () => {
    setClinicData({
      clinicName: "",
      clinicImage: "",
      clinicStatus: "Active",
      clinicSpecialty: "",
      clinicBio: "",
      clinicAbout: "",
      clinicAddress: "",
      clinicLocation: { lat: 43.65107, lng: -79.347015 },
    });
  };

  const handleAddressSelect = (address) => {
    const placeId = address.value.place_id; // Extract place_id
    fetchPlaceDetails(placeId); // Fetch details for place_id
  };

  const fetchPlaceDetails = (placeId) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement('div') // Temporary div for service
    );

    service.getDetails(
      { placeId },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const { lat, lng } = place.geometry.location;
          setClinicData((prevData) => ({
            ...prevData,
            clinicCurrentLocation: { lat: lat(), lng: lng() },
          }));
        } else {
          console.error('Error fetching place details:', status);
        }
      }
    );
  };

  if (!show || !isLoaded) return null;

  return (
    <div className="add-clinic-modal-overlay">
      <div className="add-clinic-modal-content">
        <div className="add-clinic-modal-header">
          <h2>{isEdit ? "Edit Clinic Branch" : "Add New Clinic Branch"}</h2>
          <button
            className="add-clinic-close-btn"
            onClick={() => {
              ClearAllField();
              handleClose();
            }}
          >
            &times;
          </button>
        </div>
        <div className="add-clinic-modal-body">
          <form>
            <div className="add-clinic-form-group">
              <label>Clinic Name</label>
              <input
                type="text"
                name="clinicName"
                value={clinicData.clinicName}
                onChange={handleInputChange}
                className="add-clinic-form-input"
              />
            </div>
            {!isEdit && (
              <div className="add-clinic-form-group">
                <label>Upload Clinic Logo</label>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="add-clinic-form-input"
                />
              </div>
            )}
            <div className="add-clinic-form-group">
              <label>Clinic Status</label>
              <select
                name="clinicStatus"
                value={clinicData.clinicStatus}
                onChange={handleInputChange}
                className="add-clinic-form-select"
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>

            <div className="add-clinic-form-group">
              <label>Clinic Specialty</label>
              <Select
                // styles={customStyles}
                name="clinicSpecialty"
                value={
                  clinicData.clinicSpecialty
                    ? {
                      value: clinicData.clinicSpecialty,
                      label: clinicData.clinicSpecialty,
                    }
                    : null
                }
                onChange={(selectedOption) =>
                  handleInputChange({
                    target: {
                      name: "clinicSpecialty",
                      value: selectedOption.value,
                    },
                  })
                }
                options={specialities}
                placeholder="Select Specialty"
              />
            </div>

            <div className="add-clinic-form-group ">
              <label>Clinic Bio</label>

              <textarea
                type="text"
                name="clinicBio"
                placeholder="Enter clinic bio"
                value={clinicData.clinicBio}
                onChange={handleInputChange}
                className="add-clinic-form-input"
              ></textarea>
            </div>
            <div className="add-clinic-form-group">
              <label>Clinic About</label>

              <textarea
                type="text"
                name="clinicAbout"
                placeholder="Enter clinic about"
                value={clinicData.clinicAbout}
                onChange={handleInputChange}
                className="add-clinic-form-input"
              ></textarea>
            </div>
            <div className="add-clinic-form-group">
              <label>Clinic Address</label>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_MAP_KEY}
                // onSelect={handleAddressSelect}
                selectProps={{
                  onChange: handleAddressSelect,
                }}
              />
              {/* <input
                type="text"
                name="clinicAddress"
                value={clinicData.clinicAddress}
                onChange={handleAddressSearch}
                className="add-clinic-form-input"
              /> */}
            </div>
          </form>
          {/* Google Map Container */}
          <div className="add-clinic-map-container">
            <GoogleMap
              center={
                clinicData.clinicCurrentLocation
                  ? clinicData.clinicCurrentLocation
                  : clinicData.clinicLocation
              }
              zoom={12}
              onClick={(e) => {
                setClinicData({
                  ...clinicData,
                  clinicCurrentLocation: {
                    lat: e?.latLng?.lat(),
                    lng: e?.latLng?.lng(),
                  },
                });
              }}
              mapContainerStyle={{ height: "300px", width: "100%" }}
            >
              {hospitalData.map((hospital) => (
                <Marker
                  key={hospital.Hospital_id}
                  // onClick={() => handleMarkerClick(hospital)}
                  position={{
                    lat: parseFloat(hospital.latitude),
                    lng: parseFloat(hospital.longitude),
                  }}
                  title={hospital.Hospital_Name}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                  }}
                />
              ))}
              {clinicData?.clinicCurrentLocation && (
                <Marker
                  draggable={true}
                  position={{
                    lat: clinicData.clinicCurrentLocation.lat,
                    lng: clinicData.clinicCurrentLocation.lng,
                  }}
                  onDragEnd={(e) => {
                    const newLat = e.latLng.lat();
                    const newLng = e.latLng.lng();
                    setClinicData((prevData) => ({
                      ...prevData,
                      clinicCurrentLocation: { lat: newLat, lng: newLng },
                    }));
                  }}
                  icon={{
                    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png", // Set marker color to green
                  }}
                />
              )}
              {/* <Marker position={clinicData.clinicLocation} /> */}
            </GoogleMap>
          </div>
        </div>
        <div className="add-clinic-modal-footer">
          <button className="add-clinic-cancel-btn" onClick={handleClose}>
            Cancel
          </button>
          <button className="add-clinic-save-btn" onClick={handleSave}>
            {isEdit ? "Save Changes" : "Save Clinic Branch"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddClinicBranchModal;
